import { default as affiliatesb843RVPRAZMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45viewsIRU8Xqi2fJMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngosm2IaC5lBDKMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_93hmyvCW3zIhMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as index6szrphu43JMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_93FjorCtwmAyMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as indexaQU0QDZ2xmMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_939BeteEHUdIMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_93elnvCfpd7tMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as editCb4op47gUUMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexcnfI7G57DtMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticsW1v0sSf0liMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexbp6anTHAM5Meta } from "/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexktRJnK9XdCMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareT2iMMgwqy6Meta } from "/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showsDOtlGxTiCMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexS1j5YF6trIMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadsEYpC7KrROEMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guidesGhQ3P4Vj5DMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/guides.vue?macro=true";
import { default as homepNGvoR9bqqMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/home.vue?macro=true";
import { default as indexcuDVarHVxcMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrations6ctmEEVjQKMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as loginZDPIQYck30Meta } from "/codebuild/output/src714249363/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templatesIw5EBqrhsiMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencerslXfyC0YlUpMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_937t2PjKiBveMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callbackxGtqM2G2GPMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callbackwJd3sWyl1tMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callbackR0qxL72jf0Meta } from "/codebuild/output/src714249363/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partnersttO32hk4bfMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_93c1tFu0AoT7Meta } from "/codebuild/output/src714249363/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexb8nkCKUmcwMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricing13rqpDZpKDMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyDeO2OE7ARhMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as registerA9cyiOnAweMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokenseers1eqZ24Meta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accounto7egNucfJ9Meta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as adminZTBRsR8lPyMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billingnmrh7UlZfjMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connections9dnQar0SdnMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainPpiv0mCUS4Meta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as email_45settingsY2yOkEX6HfMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings/email-settings.vue?macro=true";
import { default as indexQ0G7aqqrctMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as passwordCnAN02M6VfMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profileeSIjMRdw4zMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacesHlesZ2aVLuMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsLtxBhs4V8bMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/settings.vue?macro=true";
import { default as error7JORFzlps5Meta } from "/codebuild/output/src714249363/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successLnBe8eDjnVMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsqFBUhrDa5QMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as editKnFMBuc0pLMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as indexdcyUXGBCl8Meta } from "/codebuild/output/src714249363/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as index6O7SxfLynnMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as sharetgzBIZQZtmMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as show06w7YehuHlMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as indexxMUC7SBtjFMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as indexyJJWJQJEVdMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadsLKxE1VFjuGMeta } from "/codebuild/output/src714249363/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: affiliatesb843RVPRAZMeta?.name ?? "affiliates",
    path: affiliatesb843RVPRAZMeta?.path ?? "/affiliates",
    meta: affiliatesb843RVPRAZMeta || {},
    alias: affiliatesb843RVPRAZMeta?.alias || [],
    redirect: affiliatesb843RVPRAZMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: database_45viewsIRU8Xqi2fJMeta?.name ?? "database-views",
    path: database_45viewsIRU8Xqi2fJMeta?.path ?? "/database-views",
    meta: database_45viewsIRU8Xqi2fJMeta || {},
    alias: database_45viewsIRU8Xqi2fJMeta?.alias || [],
    redirect: database_45viewsIRU8Xqi2fJMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/database-views.vue").then(m => m.default || m)
  },
  {
    name: discount_45students_45academics_45ngosm2IaC5lBDKMeta?.name ?? "discount-students-academics-ngos",
    path: discount_45students_45academics_45ngosm2IaC5lBDKMeta?.path ?? "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngosm2IaC5lBDKMeta || {},
    alias: discount_45students_45academics_45ngosm2IaC5lBDKMeta?.alias || [],
    redirect: discount_45students_45academics_45ngosm2IaC5lBDKMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/discount-students-academics-ngos.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hmyvCW3zIhMeta?.name ?? "features-slug",
    path: _91slug_93hmyvCW3zIhMeta?.path ?? "/features/:slug()",
    meta: _91slug_93hmyvCW3zIhMeta || {},
    alias: _91slug_93hmyvCW3zIhMeta?.alias || [],
    redirect: _91slug_93hmyvCW3zIhMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: index6szrphu43JMeta?.name ?? "features",
    path: index6szrphu43JMeta?.path ?? "/features",
    meta: index6szrphu43JMeta || {},
    alias: index6szrphu43JMeta?.alias || [],
    redirect: index6szrphu43JMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjorCtwmAyMeta?.name ?? "form-templates-slug",
    path: _91slug_93FjorCtwmAyMeta?.path ?? "/form-templates/:slug()",
    meta: _91slug_93FjorCtwmAyMeta || {},
    alias: _91slug_93FjorCtwmAyMeta?.alias || [],
    redirect: _91slug_93FjorCtwmAyMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/form-templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexaQU0QDZ2xmMeta?.name ?? "form-templates",
    path: indexaQU0QDZ2xmMeta?.path ?? "/form-templates",
    meta: indexaQU0QDZ2xmMeta || {},
    alias: indexaQU0QDZ2xmMeta?.alias || [],
    redirect: indexaQU0QDZ2xmMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/form-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939BeteEHUdIMeta?.name ?? "form-templates-industries-slug",
    path: _91slug_939BeteEHUdIMeta?.path ?? "/form-templates/industries/:slug()",
    meta: _91slug_939BeteEHUdIMeta || {},
    alias: _91slug_939BeteEHUdIMeta?.alias || [],
    redirect: _91slug_939BeteEHUdIMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/form-templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93elnvCfpd7tMeta?.name ?? "form-templates-types-slug",
    path: _91slug_93elnvCfpd7tMeta?.path ?? "/form-templates/types/:slug()",
    meta: _91slug_93elnvCfpd7tMeta || {},
    alias: _91slug_93elnvCfpd7tMeta?.alias || [],
    redirect: _91slug_93elnvCfpd7tMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/form-templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: editCb4op47gUUMeta?.name ?? "forms-slug-edit",
    path: editCb4op47gUUMeta?.path ?? "/forms/:slug()/edit",
    meta: editCb4op47gUUMeta || {},
    alias: editCb4op47gUUMeta?.alias || [],
    redirect: editCb4op47gUUMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexcnfI7G57DtMeta?.name ?? "forms-slug",
    path: indexcnfI7G57DtMeta?.path ?? "/forms/:slug()",
    meta: indexcnfI7G57DtMeta || {},
    alias: indexcnfI7G57DtMeta?.alias || [],
    redirect: indexcnfI7G57DtMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showsDOtlGxTiCMeta?.name ?? undefined,
    path: showsDOtlGxTiCMeta?.path ?? "/forms/:slug()/show",
    meta: showsDOtlGxTiCMeta || {},
    alias: showsDOtlGxTiCMeta?.alias || [],
    redirect: showsDOtlGxTiCMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: analyticsW1v0sSf0liMeta?.name ?? "forms-slug-show-analytics",
    path: analyticsW1v0sSf0liMeta?.path ?? "analytics",
    meta: analyticsW1v0sSf0liMeta || {},
    alias: analyticsW1v0sSf0liMeta?.alias || [],
    redirect: analyticsW1v0sSf0liMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexbp6anTHAM5Meta?.name ?? "forms-slug-show",
    path: indexbp6anTHAM5Meta?.path ?? "",
    meta: indexbp6anTHAM5Meta || {},
    alias: indexbp6anTHAM5Meta?.alias || [],
    redirect: indexbp6anTHAM5Meta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexktRJnK9XdCMeta?.name ?? "forms-slug-show-integrations",
    path: indexktRJnK9XdCMeta?.path ?? "integrations",
    meta: indexktRJnK9XdCMeta || {},
    alias: indexktRJnK9XdCMeta?.alias || [],
    redirect: indexktRJnK9XdCMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: shareT2iMMgwqy6Meta?.name ?? "forms-slug-show-share",
    path: shareT2iMMgwqy6Meta?.path ?? "share",
    meta: shareT2iMMgwqy6Meta || {},
    alias: shareT2iMMgwqy6Meta?.alias || [],
    redirect: shareT2iMMgwqy6Meta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexS1j5YF6trIMeta?.name ?? "forms-create",
    path: indexS1j5YF6trIMeta?.path ?? "/forms/create",
    meta: indexS1j5YF6trIMeta || {},
    alias: indexS1j5YF6trIMeta?.alias || [],
    redirect: indexS1j5YF6trIMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsEYpC7KrROEMeta?.name ?? "forms-uploads",
    path: uploadsEYpC7KrROEMeta?.path ?? "/forms/uploads",
    meta: uploadsEYpC7KrROEMeta || {},
    alias: uploadsEYpC7KrROEMeta?.alias || [],
    redirect: uploadsEYpC7KrROEMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/forms/uploads.vue").then(m => m.default || m)
  },
  {
    name: guidesGhQ3P4Vj5DMeta?.name ?? "guides",
    path: guidesGhQ3P4Vj5DMeta?.path ?? "/guides",
    meta: guidesGhQ3P4Vj5DMeta || {},
    alias: guidesGhQ3P4Vj5DMeta?.alias || [],
    redirect: guidesGhQ3P4Vj5DMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: homepNGvoR9bqqMeta?.name ?? "home",
    path: homepNGvoR9bqqMeta?.path ?? "/home",
    meta: homepNGvoR9bqqMeta || {},
    alias: homepNGvoR9bqqMeta?.alias || [],
    redirect: homepNGvoR9bqqMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexcuDVarHVxcMeta?.name ?? "index",
    path: indexcuDVarHVxcMeta?.path ?? "/",
    meta: indexcuDVarHVxcMeta || {},
    alias: indexcuDVarHVxcMeta?.alias || [],
    redirect: indexcuDVarHVxcMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrations6ctmEEVjQKMeta?.name ?? "integrations",
    path: integrations6ctmEEVjQKMeta?.path ?? "/integrations",
    meta: integrations6ctmEEVjQKMeta || {},
    alias: integrations6ctmEEVjQKMeta?.alias || [],
    redirect: integrations6ctmEEVjQKMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: loginZDPIQYck30Meta?.name ?? "login",
    path: loginZDPIQYck30Meta?.path ?? "/login",
    meta: loginZDPIQYck30Meta || {},
    alias: loginZDPIQYck30Meta?.alias || [],
    redirect: loginZDPIQYck30Meta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45templatesIw5EBqrhsiMeta?.name ?? "my-templates",
    path: my_45templatesIw5EBqrhsiMeta?.path ?? "/my-templates",
    meta: my_45templatesIw5EBqrhsiMeta || {},
    alias: my_45templatesIw5EBqrhsiMeta?.alias || [],
    redirect: my_45templatesIw5EBqrhsiMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/my-templates.vue").then(m => m.default || m)
  },
  {
    name: notion_45ambassadors_45influencerslXfyC0YlUpMeta?.name ?? "notion-ambassadors-influencers",
    path: notion_45ambassadors_45influencerslXfyC0YlUpMeta?.path ?? "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencerslXfyC0YlUpMeta || {},
    alias: notion_45ambassadors_45influencerslXfyC0YlUpMeta?.alias || [],
    redirect: notion_45ambassadors_45influencerslXfyC0YlUpMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/notion-ambassadors-influencers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_937t2PjKiBveMeta?.name ?? "notion-form-slug",
    path: _91slug_937t2PjKiBveMeta?.path ?? "/notion-form/:slug()",
    meta: _91slug_937t2PjKiBveMeta || {},
    alias: _91slug_937t2PjKiBveMeta?.alias || [],
    redirect: _91slug_937t2PjKiBveMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/notion-form/[slug].vue").then(m => m.default || m)
  },
  {
    name: callbackxGtqM2G2GPMeta?.name ?? "notion-callback",
    path: callbackxGtqM2G2GPMeta?.path ?? "/notion/callback",
    meta: callbackxGtqM2G2GPMeta || {},
    alias: callbackxGtqM2G2GPMeta?.alias || [],
    redirect: callbackxGtqM2G2GPMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/notion/callback.vue").then(m => m.default || m)
  },
  {
    name: guest_45callbackwJd3sWyl1tMeta?.name ?? "notion-guest-callback",
    path: guest_45callbackwJd3sWyl1tMeta?.path ?? "/notion/guest-callback",
    meta: guest_45callbackwJd3sWyl1tMeta || {},
    alias: guest_45callbackwJd3sWyl1tMeta?.alias || [],
    redirect: guest_45callbackwJd3sWyl1tMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/notion/guest-callback.vue").then(m => m.default || m)
  },
  {
    name: callbackR0qxL72jf0Meta?.name ?? "oauth-callback",
    path: callbackR0qxL72jf0Meta?.path ?? "/oauth/callback",
    meta: callbackR0qxL72jf0Meta || {},
    alias: callbackR0qxL72jf0Meta?.alias || [],
    redirect: callbackR0qxL72jf0Meta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: partnersttO32hk4bfMeta?.name ?? "partners",
    path: partnersttO32hk4bfMeta?.path ?? "/partners",
    meta: partnersttO32hk4bfMeta || {},
    alias: partnersttO32hk4bfMeta?.alias || [],
    redirect: partnersttO32hk4bfMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: _91token_93c1tFu0AoT7Meta?.name ?? "password-reset-token",
    path: _91token_93c1tFu0AoT7Meta?.path ?? "/password/reset/:token()",
    meta: _91token_93c1tFu0AoT7Meta || {},
    alias: _91token_93c1tFu0AoT7Meta?.alias || [],
    redirect: _91token_93c1tFu0AoT7Meta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: indexb8nkCKUmcwMeta?.name ?? "password-reset",
    path: indexb8nkCKUmcwMeta?.path ?? "/password/reset",
    meta: indexb8nkCKUmcwMeta || {},
    alias: indexb8nkCKUmcwMeta?.alias || [],
    redirect: indexb8nkCKUmcwMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: pricing13rqpDZpKDMeta?.name ?? "pricing",
    path: pricing13rqpDZpKDMeta?.path ?? "/pricing",
    meta: pricing13rqpDZpKDMeta || {},
    alias: pricing13rqpDZpKDMeta?.alias || [],
    redirect: pricing13rqpDZpKDMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyDeO2OE7ARhMeta?.name ?? "privacy-policy",
    path: privacy_45policyDeO2OE7ARhMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyDeO2OE7ARhMeta || {},
    alias: privacy_45policyDeO2OE7ARhMeta?.alias || [],
    redirect: privacy_45policyDeO2OE7ARhMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerA9cyiOnAweMeta?.name ?? "register",
    path: registerA9cyiOnAweMeta?.path ?? "/register",
    meta: registerA9cyiOnAweMeta || {},
    alias: registerA9cyiOnAweMeta?.alias || [],
    redirect: registerA9cyiOnAweMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsLtxBhs4V8bMeta?.name ?? undefined,
    path: settingsLtxBhs4V8bMeta?.path ?? "/settings",
    meta: settingsLtxBhs4V8bMeta || {},
    alias: settingsLtxBhs4V8bMeta?.alias || [],
    redirect: settingsLtxBhs4V8bMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokenseers1eqZ24Meta?.name ?? "settings-access-tokens",
    path: access_45tokenseers1eqZ24Meta?.path ?? "access-tokens",
    meta: access_45tokenseers1eqZ24Meta || {},
    alias: access_45tokenseers1eqZ24Meta?.alias || [],
    redirect: access_45tokenseers1eqZ24Meta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accounto7egNucfJ9Meta?.name ?? "settings-account",
    path: accounto7egNucfJ9Meta?.path ?? "account",
    meta: accounto7egNucfJ9Meta || {},
    alias: accounto7egNucfJ9Meta?.alias || [],
    redirect: accounto7egNucfJ9Meta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminZTBRsR8lPyMeta?.name ?? "settings-admin",
    path: adminZTBRsR8lPyMeta?.path ?? "admin",
    meta: adminZTBRsR8lPyMeta || {},
    alias: adminZTBRsR8lPyMeta?.alias || [],
    redirect: adminZTBRsR8lPyMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingnmrh7UlZfjMeta?.name ?? "settings-billing",
    path: billingnmrh7UlZfjMeta?.path ?? "billing",
    meta: billingnmrh7UlZfjMeta || {},
    alias: billingnmrh7UlZfjMeta?.alias || [],
    redirect: billingnmrh7UlZfjMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connections9dnQar0SdnMeta?.name ?? "settings-connections",
    path: connections9dnQar0SdnMeta?.path ?? "connections",
    meta: connections9dnQar0SdnMeta || {},
    alias: connections9dnQar0SdnMeta?.alias || [],
    redirect: connections9dnQar0SdnMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: custom_45domainPpiv0mCUS4Meta?.name ?? "settings-custom-domain",
    path: custom_45domainPpiv0mCUS4Meta?.path ?? "custom-domain",
    meta: custom_45domainPpiv0mCUS4Meta || {},
    alias: custom_45domainPpiv0mCUS4Meta?.alias || [],
    redirect: custom_45domainPpiv0mCUS4Meta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: email_45settingsY2yOkEX6HfMeta?.name ?? "settings-email-settings",
    path: email_45settingsY2yOkEX6HfMeta?.path ?? "email-settings",
    meta: email_45settingsY2yOkEX6HfMeta || {},
    alias: email_45settingsY2yOkEX6HfMeta?.alias || [],
    redirect: email_45settingsY2yOkEX6HfMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings/email-settings.vue").then(m => m.default || m)
  },
  {
    name: indexQ0G7aqqrctMeta?.name ?? "settings",
    path: indexQ0G7aqqrctMeta?.path ?? "",
    meta: indexQ0G7aqqrctMeta || {},
    alias: indexQ0G7aqqrctMeta?.alias || [],
    redirect: indexQ0G7aqqrctMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordCnAN02M6VfMeta?.name ?? "settings-password",
    path: passwordCnAN02M6VfMeta?.path ?? "password",
    meta: passwordCnAN02M6VfMeta || {},
    alias: passwordCnAN02M6VfMeta?.alias || [],
    redirect: passwordCnAN02M6VfMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileeSIjMRdw4zMeta?.name ?? "settings-profile",
    path: profileeSIjMRdw4zMeta?.path ?? "profile",
    meta: profileeSIjMRdw4zMeta || {},
    alias: profileeSIjMRdw4zMeta?.alias || [],
    redirect: profileeSIjMRdw4zMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspacesHlesZ2aVLuMeta?.name ?? "settings-workspaces",
    path: workspacesHlesZ2aVLuMeta?.path ?? "workspaces",
    meta: workspacesHlesZ2aVLuMeta || {},
    alias: workspacesHlesZ2aVLuMeta?.alias || [],
    redirect: workspacesHlesZ2aVLuMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: error7JORFzlps5Meta?.name ?? "subscriptions-error",
    path: error7JORFzlps5Meta?.path ?? "/subscriptions/error",
    meta: error7JORFzlps5Meta || {},
    alias: error7JORFzlps5Meta?.alias || [],
    redirect: error7JORFzlps5Meta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successLnBe8eDjnVMeta?.name ?? "subscriptions-success",
    path: successLnBe8eDjnVMeta?.path ?? "/subscriptions/success",
    meta: successLnBe8eDjnVMeta || {},
    alias: successLnBe8eDjnVMeta?.alias || [],
    redirect: successLnBe8eDjnVMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsqFBUhrDa5QMeta?.name ?? "terms-conditions",
    path: terms_45conditionsqFBUhrDa5QMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsqFBUhrDa5QMeta || {},
    alias: terms_45conditionsqFBUhrDa5QMeta?.alias || [],
    redirect: terms_45conditionsqFBUhrDa5QMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: editKnFMBuc0pLMeta?.name ?? "views-slug-edit",
    path: editKnFMBuc0pLMeta?.path ?? "/views/:slug()/edit",
    meta: editKnFMBuc0pLMeta || {},
    alias: editKnFMBuc0pLMeta?.alias || [],
    redirect: editKnFMBuc0pLMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/views/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexdcyUXGBCl8Meta?.name ?? "views-slug",
    path: indexdcyUXGBCl8Meta?.path ?? "/views/:slug()",
    meta: indexdcyUXGBCl8Meta || {},
    alias: indexdcyUXGBCl8Meta?.alias || [],
    redirect: indexdcyUXGBCl8Meta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/views/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: show06w7YehuHlMeta?.name ?? undefined,
    path: show06w7YehuHlMeta?.path ?? "/views/:slug()/show",
    meta: show06w7YehuHlMeta || {},
    alias: show06w7YehuHlMeta?.alias || [],
    redirect: show06w7YehuHlMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/views/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: index6O7SxfLynnMeta?.name ?? "views-slug-show",
    path: index6O7SxfLynnMeta?.path ?? "",
    meta: index6O7SxfLynnMeta || {},
    alias: index6O7SxfLynnMeta?.alias || [],
    redirect: index6O7SxfLynnMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/views/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: sharetgzBIZQZtmMeta?.name ?? "views-slug-show-share",
    path: sharetgzBIZQZtmMeta?.path ?? "share",
    meta: sharetgzBIZQZtmMeta || {},
    alias: sharetgzBIZQZtmMeta?.alias || [],
    redirect: sharetgzBIZQZtmMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/views/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexxMUC7SBtjFMeta?.name ?? "views-create",
    path: indexxMUC7SBtjFMeta?.path ?? "/views/create",
    meta: indexxMUC7SBtjFMeta || {},
    alias: indexxMUC7SBtjFMeta?.alias || [],
    redirect: indexxMUC7SBtjFMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/views/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexyJJWJQJEVdMeta?.name ?? "views",
    path: indexyJJWJQJEVdMeta?.path ?? "/views",
    meta: indexyJJWJQJEVdMeta || {},
    alias: indexyJJWJQJEVdMeta?.alias || [],
    redirect: indexyJJWJQJEVdMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/views/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsLKxE1VFjuGMeta?.name ?? "views-uploads",
    path: uploadsLKxE1VFjuGMeta?.path ?? "/views/uploads",
    meta: uploadsLKxE1VFjuGMeta || {},
    alias: uploadsLKxE1VFjuGMeta?.alias || [],
    redirect: uploadsLKxE1VFjuGMeta?.redirect,
    component: () => import("/codebuild/output/src714249363/src/notionforms/client/pages/views/uploads.vue").then(m => m.default || m)
  }
]